import { ApiContext } from '../../types';
import { cancelBooking } from '@wix/ambassador-bookings-v2-booking/http';
import { CancelBookingResponse } from '@wix/ambassador-bookings-v2-booking/types';

export interface CancelSingleArgs {
  bookingId: string;
  bookingRevision?: any;
}

export const cancelSingle = async ({
  bookingId,
  bookingRevision,
  flowAPI,
}: CancelSingleArgs & ApiContext): Promise<CancelBookingResponse> => {
  const { httpClient } = flowAPI;

  return (
    await httpClient.request(
      cancelBooking({
        bookingId,
        revision: bookingRevision,
        participantNotification: { notifyParticipants: true },
        flowControlSettings: { withRefund: true },
      }),
    )
  ).data;
};
